import React, { useEffect, useRef, useState } from "react";
import { Input } from "../../ui/input";
import { cn } from "../../../lib/utils";
import axios from "axios";
import debounce from "lodash/debounce";
import { IconSearch, IconX } from "@tabler/icons-react";

const API_BASE_URL = process.env.NODE_ENV === 'production' 
  ? 'https://api.remoteworkkit.com'
  : 'http://localhost:3001/api';

const CityAutocomplete = ({
  value,
  onChange,
  placeholder,
  className,
  onPlaceSelected,
  error,
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const inputRef = useRef(null);
  const abortControllerRef = useRef(null);
  const autocompleteCache = useRef({});
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const suggestionsRef = useRef(null);

  // Update internal state when value prop changes
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const fetchSuggestions = async (input) => {
    // Early return if input is empty
    if (!input?.trim()) {
      setSuggestions([]);
      return;
    }

    // Check cache first
    const cacheKey = input.trim().toLowerCase();
    if (autocompleteCache.current[cacheKey]) {
      console.log('Using cached results for:', cacheKey);
      setSuggestions(autocompleteCache.current[cacheKey]);
      return;
    }

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();

    try {
      const response = await axios.get(`${API_BASE_URL}/places/autocomplete`, {
        params: { input: input.trim() },
        signal: abortControllerRef.current.signal
      });
      
      const results = Array.isArray(response.data) ? response.data : [];
      setSuggestions(results);
      
      // Cache the results
      autocompleteCache.current[cacheKey] = results;
      
      // Limit cache size to prevent memory issues
      const cacheKeys = Object.keys(autocompleteCache.current);
      if (cacheKeys.length > 50) {
        delete autocompleteCache.current[cacheKeys[0]];
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error("Error fetching suggestions:", error);
        setSuggestions([]);
      }
    }
  };

  const debouncedFetch = useRef(
    debounce((value) => {
      fetchSuggestions(value);
    }, 350)
  ).current;

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setSelectedSuggestion(null);
    
    // If the input is empty, reset the location data
    if (!value || value.trim() === '') {
      onChange('');
      onPlaceSelected(null);
      return;
    }
    
    // Only show suggestions and fetch if we have at least 2 characters
    if (value.trim().length >= 2) {
      setShowSuggestions(true);
      debouncedFetch(value);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  };

  useEffect(() => {
    return () => {
      debouncedFetch.cancel();
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [debouncedFetch]);

  const handleSuggestionClick = async (suggestion) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/places/details`, {
        params: { placeId: suggestion.place_id }
      });
      const place = response.data;
      
      if (!place?.geometry?.location) {
        throw new Error('Invalid place details response');
      }

      const { lat, lng } = place.geometry.location;
      if (typeof lat !== 'number' || typeof lng !== 'number') {
        throw new Error('Invalid lat/lng values');
      }

      // Update all states at once to reduce renders
      const placeData = {
        name: place.name,
        lat,
        lng,
        placeData: place
      };
      
      setInputValue(place.name);
      onChange(place.name);
      onPlaceSelected(placeData);
      setShowSuggestions(false);
      setSelectedSuggestion(suggestion);
    } catch (error) {
      console.error("Error fetching place details:", error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setSelectedIndex((prev) =>
        prev < suggestions.length - 1 ? prev + 1 : prev
      );
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setSelectedIndex((prev) => (prev > -1 ? prev - 1 : prev));
    } else if (e.key === "Enter" && selectedIndex > -1) {
      e.preventDefault();
      handleSuggestionClick(suggestions[selectedIndex]);
    }
  };

  const handleClearInput = () => {
    setInputValue("");
    setSuggestions([]);
    setShowSuggestions(false);
    setSelectedSuggestion(null);
    onChange('');
    onPlaceSelected(null);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleBlur = () => {
    // Short delay to allow click events on suggestions to fire first
    setTimeout(() => {
      setShowSuggestions(false);
      
      // If no suggestion was selected and there's text in the input,
      // clear the input
      if (!selectedSuggestion && inputValue.trim()) {
        setInputValue("");
        onChange(null);
        onPlaceSelected(null);
      }
    }, 200);
  };

  // Close suggestions when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target) &&
        !inputRef.current.contains(event.target)
      ) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative">
      <div className="relative">
        <div className="absolute left-3 top-1/2 -translate-y-1/2 text-muted-foreground">
          <IconSearch size={16} />
        </div>
        <Input
          ref={inputRef}
          type="text"
          placeholder={placeholder}
          value={inputValue}
          onChange={handleInputChange}
          onFocus={() => {
            if (inputValue) {
              setShowSuggestions(true);
            }
          }}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          className={cn(
            "pl-10 pr-10", 
            className,
            error && "border-destructive focus:ring-destructive"
          )}
          autoComplete="off"
          role="combobox"
          aria-expanded={showSuggestions}
          aria-autocomplete="list"
          aria-controls="suggestions-list"
          aria-activedescendant={
            selectedIndex >= 0 ? `suggestion-${selectedIndex}` : undefined
          }
        />
        {inputValue && (
          <button
            type="button"
            onClick={handleClearInput}
            className="absolute right-3 top-1/2 -translate-y-1/2 text-muted-foreground hover:text-foreground"
          >
            <IconX size={16} />
          </button>
        )}
      </div>

      {showSuggestions && suggestions.length > 0 && (
        <ul
          id="suggestions-list"
          role="listbox"
          className="absolute z-10 w-full mt-1 bg-background border rounded-md shadow-lg"
        >
          {suggestions.map((suggestion, index) => (
            <li
              id={`suggestion-${index}`}
              role="option"
              aria-selected={index === selectedIndex}
              key={suggestion.place_id}
              className={cn(
                "px-3 py-2 hover:bg-accent cursor-pointer",
                index === selectedIndex && "bg-accent"
              )}
              onMouseDown={(e) => {
                e.preventDefault();
                handleSuggestionClick(suggestion);
              }}
            >
              {suggestion.description}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CityAutocomplete;