import React from "react";
import { Label } from "./label";

const TimeSelect = ({ label, value, onChange, className }) => {
  // Parse the current time value
  const [hours, minutes] = value.split(":");
  const hour = parseInt(hours, 10);
  const period = hour >= 12 ? "PM" : "AM";

  // Convert 24h to 12h format
  const displayHour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;

  const handleHourChange = (e) => {
    const newHour = parseInt(e.target.value, 10);
    const hour24 =
      period === "PM"
        ? newHour === 12
          ? 12
          : newHour + 12
        : newHour === 12
        ? 0
        : newHour;
    onChange(`${hour24.toString().padStart(2, "0")}:${minutes}`);
  };

  const handleMinuteChange = (e) => {
    const newMinutes = e.target.value.padStart(2, "0");
    const hour24 =
      period === "PM"
        ? displayHour === 12
          ? 12
          : displayHour + 12
        : displayHour === 12
        ? 0
        : displayHour;
    onChange(`${hour24.toString().padStart(2, "0")}:${newMinutes}`);
  };

  const handlePeriodChange = (e) => {
    const newPeriod = e.target.value;
    const hour24 =
      newPeriod === "PM"
        ? displayHour === 12
          ? 12
          : displayHour + 12
        : displayHour === 12
        ? 0
        : displayHour;
    onChange(`${hour24.toString().padStart(2, "0")}:${minutes}`);
  };

  return (
    <div className={className}>
      {label && (
        <Label
          className="mb-2"
          id={`${label.toLowerCase().replace(/\s+/g, "-")}-label`}
        >
          {label}
        </Label>
      )}
      <div className="flex gap-2 items-center">
        <div className="relative">
          <select
            value={displayHour}
            onChange={handleHourChange}
            aria-label="Hour"
            className="w-[72px] h-[42px] rounded-lg border border-input bg-background px-3 text-base font-normal hover:bg-accent/10 focus:bg-accent/10 transition-colors"
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((h) => (
              <option key={h} value={h}>
                {h.toString().padStart(2, "0")}
              </option>
            ))}
          </select>
        </div>
        <div className="relative">
          <select
            value={minutes}
            onChange={handleMinuteChange}
            aria-label="Minutes"
            className="w-[72px] h-[42px] rounded-lg border border-input bg-background px-3 text-base font-normal hover:bg-accent/10 focus:bg-accent/10 transition-colors"
          >
            {["00", "15", "30", "45"].map((m) => (
              <option key={m} value={m}>
                {m.toString().padStart(2, "0")}
              </option>
            ))}
          </select>
        </div>
        <div className="relative">
          <select
            value={period}
            onChange={handlePeriodChange}
            aria-label="AM/PM"
            className="w-[72px] h-[42px] rounded-lg border border-input bg-background px-3 text-base font-normal hover:bg-accent/10 focus:bg-accent/10 transition-colors"
          >
            <option value="AM">AM</option>
            <option value="PM">PM</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default TimeSelect;
