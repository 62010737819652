import React, { useEffect, useRef, memo } from "react";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { defaults as defaultControls, ZoomSlider } from "ol/control";
import { fromLonLat } from "ol/proj";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import LineString from "ol/geom/LineString";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { Circle, Style, Stroke, Fill } from "ol/style";
import Overlay from "ol/Overlay";
import { boundingExtent } from "ol/extent";

const TimeZoneMap = memo(({ homeLocation, destinations, workHours }) => {
  const mapRef = useRef();
  const mapInstance = useRef(null);
  const tooltipRef = useRef();

  // Custom zoom control styles
  const customZoomStyles = `
    .ol-zoom {
      top: 1rem;
      left: 1rem;
      background: transparent;
      margin: 0.75rem;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    .ol-zoom button {
      background: white !important;
      border: 1px solid #e2e8f0 !important;
      color: #64748b !important;
      margin: 0 !important;
      width: 40px !important;
      height: 40px !important;
      font-size: 1.5rem !important;
      border-radius: 8px !important;
      box-shadow: 0 1px 3px rgba(0,0,0,0.1) !important;
    }
    .ol-zoom button:hover {
      background: #f8fafc !important;
      color: #2563eb !important;
    }
    .ol-zoom-in {
      border-radius: 8px !important;
    }
    .ol-zoom-out {
      border-radius: 8px !important;
    }
  `;

  const updateMapFeatures = React.useCallback(() => {
    const features = [];
    const vectorSource = new VectorSource();
    let lastPoint = null;
    let hasHomeLocation = false;
    let hasDestination = false;

    // Add home location if exists
    if (homeLocation?.lat && homeLocation?.lng) {
      const homePoint = new Feature({
        geometry: new Point(fromLonLat([homeLocation.lng, homeLocation.lat])),
        type: "home",
        location: homeLocation,
      });

      homePoint.setStyle(
        new Style({
          image: new Circle({
            radius: 8,
            fill: new Fill({ color: "#2563eb" }), // Primary blue for home
            stroke: new Stroke({ color: "#fff", width: 2 }),
          }),
        })
      );
      features.push(homePoint);
      lastPoint = [homeLocation.lng, homeLocation.lat];
      hasHomeLocation = true;
    }

    // Add destinations and connection lines
    destinations.forEach((dest) => {
      if (dest.lat && dest.lng && dest.city) {
        hasDestination = true;

        // Add destination point
        const destPoint = new Feature({
          geometry: new Point(fromLonLat([dest.lng, dest.lat])),
          type: "destination",
          location: dest,
        });

        destPoint.setStyle(
          new Style({
            image: new Circle({
              radius: 6,
              fill: new Fill({ color: "#22c55e" }), // Green for destinations
              stroke: new Stroke({ color: "#fff", width: 2 }),
            }),
          })
        );
        features.push(destPoint);

        // Add connection line from last point
        if (lastPoint) {
          const line = new Feature({
            geometry: new LineString([
              fromLonLat(lastPoint),
              fromLonLat([dest.lng, dest.lat]),
            ]),
          });

          line.setStyle(
            new Style({
              stroke: new Stroke({
                color: "rgba(37, 99, 235, 0.3)",
                width: 2,
                lineDash: [5, 5],
              }),
            })
          );
          features.push(line);
        }
        lastPoint = [dest.lng, dest.lat];
      }
    });

    // Update vector layer
    const vectorLayer = new VectorLayer({
      source: vectorSource,
    });

    vectorSource.addFeatures(features);

    // Remove old vector layers
    mapInstance.current.getLayers().forEach((layer) => {
      if (layer instanceof VectorLayer) {
        mapInstance.current.removeLayer(layer);
      }
    });

    mapInstance.current.addLayer(vectorLayer);

    // Add hover interactions
    addHoverInteractions(vectorLayer);

    // Only fit to locations if we have both home and destination
    if (hasHomeLocation && hasDestination) {
      const coordinates = [];
      if (homeLocation?.lat && homeLocation?.lng) {
        coordinates.push(fromLonLat([homeLocation.lng, homeLocation.lat]));
      }
      destinations.forEach((dest) => {
        if (dest.lat && dest.lng && dest.city) {
          coordinates.push(fromLonLat([dest.lng, dest.lat]));
        }
      });

      if (coordinates.length > 1) {
        const extent = boundingExtent(coordinates);
        const paddingFactor = 0.5; // 50% padding
        const width = Math.abs(extent[2] - extent[0]);
        const height = Math.abs(extent[3] - extent[1]);
        const paddingX = width * paddingFactor;
        const paddingY = height * paddingFactor;

        const paddedExtent = [
          extent[0] - paddingX,
          extent[1] - paddingY,
          extent[2] + paddingX,
          extent[3] + paddingY,
        ];

        mapInstance.current.getView().fit(paddedExtent, {
          padding: [50, 50, 50, 50],
          duration: 1000,
          maxZoom: 5,
        });
      }
    }
  }, [homeLocation, destinations]);

  useEffect(() => {
    // Add custom styles
    const styleId = 'ol-zoom-custom-styles';
    let styleSheet = document.getElementById(styleId);
    
    // Only create new style element if it doesn't exist
    if (!styleSheet) {
      styleSheet = document.createElement("style");
      styleSheet.id = styleId;
      styleSheet.textContent = customZoomStyles;
      document.head.appendChild(styleSheet);
    }

    // Cleanup
    return () => {
      const existingStyle = document.getElementById(styleId);
      if (existingStyle) {
        document.head.removeChild(existingStyle);
      }
    };
  }, [customZoomStyles]);

  useEffect(() => {
    if (!mapInstance.current) {
      // Initialize map
      const map = new Map({
        target: mapRef.current,
        layers: [
          new TileLayer({
            source: new OSM({
              url: "https://cartodb-basemaps-{a-d}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png",
              attributions: [],
            }),
          }),
        ],
        view: new View({
          center: fromLonLat([0, 20]),
          zoom: 1.5,
        }),
        controls: defaultControls({
          zoom: true,
          attribution: false,
          rotate: false,
        }).extend([new ZoomSlider()]),
      });

      mapInstance.current = map;

      // Create tooltip overlay
      const tooltip = new Overlay({
        element: tooltipRef.current,
        offset: [10, 0],
        positioning: "bottom-left",
      });
      map.addOverlay(tooltip);
    }

    // Update map features
    updateMapFeatures();
  }, [homeLocation, destinations, updateMapFeatures]);

  const addHoverInteractions = (vectorLayer) => {
    const map = mapInstance.current;
    const tooltip = map.getOverlays().item(0);

    map.on("pointermove", (evt) => {
      const feature = map.forEachFeatureAtPixel(
        evt.pixel,
        (feature) => feature
      );

      if (
        feature &&
        (feature.get("type") === "home" ||
          feature.get("type") === "destination")
      ) {
        const location = feature.get("location");

        tooltipRef.current.innerHTML = `
          <div class="bg-white p-2 rounded-lg shadow-lg border text-sm">
            <p class="font-medium">${location.city}</p>
          </div>
        `;
        tooltip.setPosition(evt.coordinate);
      } else {
        tooltip.setPosition(undefined);
      }
    });
  };

  return (
    <div className="relative w-full h-[300px] rounded-xl overflow-hidden">
      <div
        ref={mapRef}
        className="w-full h-full"
        style={{
          position: "relative",
        }}
      />
      <div ref={tooltipRef} className="absolute pointer-events-none" />
    </div>
  );
}, (prevProps, nextProps) => {
  // Custom comparison function to prevent unnecessary re-renders
  const homeLocationChanged = 
    prevProps.homeLocation?.lat !== nextProps.homeLocation?.lat ||
    prevProps.homeLocation?.lng !== nextProps.homeLocation?.lng;
    
  const destinationsChanged = 
    prevProps.destinations.length !== nextProps.destinations.length ||
    prevProps.destinations.some((prevDest, i) => {
      const nextDest = nextProps.destinations[i];
      return !nextDest || 
        prevDest.lat !== nextDest.lat || 
        prevDest.lng !== nextDest.lng;
    });
    
  return !(homeLocationChanged || destinationsChanged);
});

export default TimeZoneMap;
