import React from "react";
import { Checkbox } from "../../ui/checkbox";
import { Label } from "../../ui/label";
import { cn } from "../../../lib/utils";

const DAYS_OF_WEEK = [
  { id: "monday", label: "Monday" },
  { id: "tuesday", label: "Tuesday" },
  { id: "wednesday", label: "Wednesday" },
  { id: "thursday", label: "Thursday" },
  { id: "friday", label: "Friday" },
  { id: "saturday", label: "Saturday" },
  { id: "sunday", label: "Sunday" },
];

const WorkdaySelector = ({ selectedDays, onChange, className }) => {
  const handleDayToggle = (day) => {
    if (selectedDays.includes(day)) {
      onChange(selectedDays.filter((d) => d !== day));
    } else {
      onChange([...selectedDays, day]);
    }
  };

  return (
    <div className={cn("space-y-2", className)}>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2">
        {DAYS_OF_WEEK.map((day) => (
          <div key={day.id} className="flex items-center space-x-2">
            <Checkbox
              id={`day-${day.id}`}
              checked={selectedDays.includes(day.id)}
              onCheckedChange={() => handleDayToggle(day.id)}
              aria-label={`Select ${day.label}`}
            />
            <Label htmlFor={`day-${day.id}`} className="text-sm cursor-pointer">
              {day.label}
            </Label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WorkdaySelector;
