import React from "react";
import { Button } from "../../ui/button";
import { Label } from "../../ui/label";
import CityAutocomplete from "./CityAutocomplete";
import { IconPlus, IconCalendar, IconX } from "@tabler/icons-react";
import moment from "moment";
import { cn } from "../../../lib/utils";

const DateSelect = ({ label, value, onChange, min, className }) => {
  // Format the date for display
  const displayDate = moment(value).format("ddd, MMM D");
  
  return (
    <div className="w-[220px]">
      <Label className="block mb-2">
        {label}
      </Label>
      <div 
        className={cn(
          "relative mt-2 cursor-pointer group",
          className
        )}
        onClick={(e) => {
          // When clicking the div, trigger the input click
          const input = e.currentTarget.querySelector('input[type="date"]');
          if (input) {
            input.showPicker();
          }
        }}
      >
        <div className="w-full px-3 py-2 border rounded-md bg-background flex items-center h-[42px]">
          <span className="flex-1">{displayDate}</span>
          <IconCalendar size={16} className="text-muted-foreground group-hover:text-foreground transition-colors" />
        </div>
        <input
          type="date"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          min={min}
          className="absolute inset-0 opacity-0 cursor-pointer"
        />
      </div>
    </div>
  );
};

const DestinationSelector = ({ destinations, onChange, errors = [] }) => {
  // Get today's date in YYYY-MM-DD format for min attribute
  const today = moment().format('YYYY-MM-DD');
  
  const addDestination = () => {
    // Get the last destination's departure date, if it exists
    const lastDestination = destinations.length > 0 
      ? destinations[destinations.length - 1] 
      : null;
    
    const startDate = lastDestination?.endDate || today;
    
    onChange([
      ...destinations,
      {
        city: "",
        lat: null,
        lng: null,
        startDate: startDate,
        endDate: startDate,
      },
    ]);
  };

  const removeDestination = (index) => {
    const newDestinations = destinations.filter((_, i) => i !== index);
    onChange(newDestinations);
  };

  const handleCitySelected = (index, place) => {
    console.log('Selected place:', JSON.stringify(place, null, 2));
    
    // Handle null place (when clearing input)
    if (!place) {
      updateDestination(index, {
        city: "",
        lat: null,
        lng: null
      });
      return;
    }
    
    // Verify lat/lng are numbers
    if (typeof place.lat !== 'number' || typeof place.lng !== 'number') {
      console.error('Invalid lat/lng types:', { lat: typeof place.lat, lng: typeof place.lng });
      return;
    }
    
    updateDestination(index, {
      city: place.name,
      lat: place.lat,
      lng: place.lng,
    });
  };

  const updateDestination = (index, data) => {
    const newDestinations = [...destinations];
    const currentDest = newDestinations[index];
    
    // Special handling for dates to ensure departure is not before arrival
    if (data.startDate && currentDest.endDate) {
      // If new arrival date is after current departure date, update departure date
      if (data.startDate > currentDest.endDate) {
        newDestinations[index] = {
          ...currentDest,
          ...data,
          endDate: data.startDate // Set departure to same as arrival
        };
        onChange(newDestinations);
        return;
      }
    }
    
    if (data.endDate && currentDest.startDate) {
      // If new departure date is before current arrival date, don't update
      if (data.endDate < currentDest.startDate) {
        return;
      }
      
      // If we're updating a departure date and there's a next destination,
      // ensure the next destination's arrival date is not before this departure date
      if (index < newDestinations.length - 1) {
        const nextDest = newDestinations[index + 1];
        if (nextDest.startDate < data.endDate) {
          // Update the next destination's arrival and departure dates
          newDestinations[index + 1] = {
            ...nextDest,
            startDate: data.endDate,
            // If departure is before the new arrival, update it too
            endDate: nextDest.endDate < data.endDate ? data.endDate : nextDest.endDate
          };
        }
      }
    }
    
    newDestinations[index] = {
      ...currentDest,
      ...data,
    };
    
    onChange(newDestinations);
  };

  return (
    <div className="space-y-8">
      {destinations.map((destination, index) => (
        <div key={index} className="relative">
          {destinations.length > 1 && (
            <Button
              type="button"
              variant="ghost"
              size="sm"
              className="absolute right-0 top-0 text-muted-foreground hover:text-destructive"
              onClick={() => removeDestination(index)}
            >
              <IconX size={16} />
            </Button>
          )}

          <div className="space-y-4">
            <h3 className="font-medium text-lg">
              Destination {index + 1}
            </h3>

            <div>
              <Label>City</Label>
              <CityAutocomplete
                placeholder="e.g., Bali, Tokyo, Barcelona"
                value={destination.city}
                onChange={(value) => updateDestination(index, { city: value })}
                onPlaceSelected={(place) => handleCitySelected(index, place)}
                className={cn(errors && errors[index] && "border-destructive")}
              />
              {errors && errors[index] && (
                <p className="text-sm text-destructive mt-1">{errors[index]}</p>
              )}
            </div>

            <div className="flex gap-4 mt-4">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 w-full">
                <DateSelect
                  label="Arrival Date"
                  value={destination.startDate}
                  onChange={(value) => updateDestination(index, { startDate: value })}
                  min={index > 0 ? destinations[index - 1]?.endDate || today : today}
                />
                
                <DateSelect
                  label="Departure Date"
                  value={destination.endDate}
                  onChange={(value) => updateDestination(index, { endDate: value })}
                  min={destination.startDate || today}
                />
              </div>
            </div>
          </div>
        </div>
      ))}

      <Button
        type="button"
        variant="outline"
        className="w-full gap-2"
        onClick={addDestination}
      >
        <IconPlus size={16} />
        Add Another Destination
      </Button>
    </div>
  );
};

export default DestinationSelector;
