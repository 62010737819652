import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import axios from "axios";
import moment from "moment-timezone";
import CityAutocomplete from "./CityAutocomplete";
import DestinationSelector from "./DestinationSelector";
import WorkdaySelector from "./WorkdaySelector";
import { Button } from "../../ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../../ui/card";
import { Label } from "../../ui/label";
import TimeSelect from "../../ui/time-select";
import { 
  IconClock, 
  IconPlane, 
  IconHome, 
  IconAlertCircle, 
  IconCalendar, 
  IconArrowRight, 
  IconShare,
  IconCheck,
  IconCopy,
  IconArrowUp,
} from "@tabler/icons-react";
import TimeZoneMap from "./TimeZoneMap";
import { throttle } from "lodash";

const API_BASE_URL = process.env.NODE_ENV === 'production' 
  ? 'https://api.remoteworkkit.com'
  : 'http://localhost:3001/api';

const WorkTimeConverter = () => {
  const STORAGE_KEY = "remoteWorkKit_userInputs";

  // Use React.useMemo for complex initial states
  const initialHomeLocation = React.useMemo(() => ({
    city: "",
    lat: null,
    lng: null,
    workHours: {
      start: "09:00",
      end: "17:00",
    },
    workDays: ["monday", "tuesday", "wednesday", "thursday", "friday"],
  }), []);

  const [homeLocation, setHomeLocation] = useState(initialHomeLocation);

  const today = moment().format('YYYY-MM-DD');

  // Same for destinations
  const initialDestination = React.useMemo(() => ({
    city: "",
    lat: null,
    lng: null,
    startDate: today,
    endDate: today,
  }), [today]);

  const [destinations, setDestinations] = useState([initialDestination]);

  const [conversionResult, setConversionResult] = useState(null);
  const [errors, setErrors] = useState({
    home: null,
    workHours: null,
    workDays: null,
    destinations: destinations.map(() => null),
  });

  const [shareStatus, setShareStatus] = useState({
    copied: false,
    error: null,
  });

  const resultRef = useRef(null);

  const [isGenerating, setIsGenerating] = useState(false);

  const scrollToResults = useCallback(() => {
    if (resultRef.current) {
      try {
        resultRef.current.scrollIntoView({ 
          behavior: "smooth", 
          block: "start" 
        });
        
        setTimeout(() => {
          const yOffset = -80;
          const y = resultRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
          
          window.scrollTo({
            top: y,
            behavior: 'smooth'
          });
        }, 100);
      } catch (error) {
        console.error("Error scrolling to results:", error);
        
        window.scrollTo(0, resultRef.current.offsetTop - 80);
      }
    }
  }, []);

  // Load saved data on mount
  useEffect(() => {
    // Clear any saved data on initial load or refresh
    localStorage.removeItem(STORAGE_KEY);
    
    // Reset to initial state
    setHomeLocation(initialHomeLocation);
    setDestinations([initialDestination]);
    setConversionResult(null);
    setErrors({
      home: null,
      workHours: null,
      workDays: null,
      destinations: destinations.map(() => null),
    });
  }, [today, initialHomeLocation, initialDestination]);

  // Use a throttled save function
  const saveToLocalStorage = useCallback(
    throttle((data) => {
      try {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
      } catch (error) {
        console.error("Error saving data:", error);
      }
    }, 1000),
    []
  );

  // Helper function to get work hours in destination timezone
  const getWorkHoursInDestTimezone = (date, homeTimezone, destTimezone) => {
    const startTime = moment
      .tz(
        `${date.format("YYYY-MM-DD")} ${homeLocation.workHours.start}`,
        "YYYY-MM-DD HH:mm",
        homeTimezone
      )
        .clone()
      .tz(destTimezone);
    const endTime = moment
      .tz(
        `${date.format("YYYY-MM-DD")} ${homeLocation.workHours.end}`,
        "YYYY-MM-DD HH:mm",
        homeTimezone
      )
        .clone()
      .tz(destTimezone);

    // Check if the converted start time is after midnight but before the original start time
    const homeStartHour = parseInt(homeLocation.workHours.start.split(':')[0]);
    const destStartHour = startTime.hour();
    
    // Calculate day offset correctly based on whether we've crossed midnight
    let dayOffset = 0;
    
    // Case 1: Start time in destination is earlier in the day (hours 0-12) 
    // while home time is later in the day (hours 12-23)
    if (destStartHour < 12 && homeStartHour >= 12) {
      dayOffset = 1; // We've crossed midnight forward (e.g., SF to Tokyo)
    }
    
    // Case 2: Start time in destination is much later (like 22:00) 
    // compared to home time early morning (like 07:00)
    else if (destStartHour > 18 && homeStartHour < 12) {
      dayOffset = -1; // We've crossed midnight backward (e.g., Tokyo to SF)
    }
    
    // Additional check: If day has clearly shifted based on date difference
    const homeDate = moment.tz(`${date.format("YYYY-MM-DD")} ${homeLocation.workHours.start}`, "YYYY-MM-DD HH:mm", homeTimezone);
    const destDate = startTime.clone();
    if (destDate.date() !== homeDate.date() && dayOffset === 0) {
      dayOffset = destDate.date() > homeDate.date() ? 1 : -1;
    }
    
    const workDays = homeLocation.workDays.map(day => {
      const dayIndex = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].indexOf(day);
      // Add 7 before modulo to handle negative indices correctly
      let newDayIndex = ((dayIndex + dayOffset) + 7) % 7;
      return ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'][newDayIndex];
    });

    return {
      start: startTime.format("HH:mm"),
      end: endTime.format("HH:mm"),
      workDays: workDays
    };
  };

  const handleHomeLocationSelected = async (place) => {
    // If place is null (cleared input), just reset the city without error
    if (!place) {
      setHomeLocation(prev => ({
        ...prev,
        city: "",
        lat: null,
        lng: null,
        timezone: null
      }));
      // Clear any previous errors
      setErrors(prev => ({
        ...prev,
        home: null
      }));
      return;
    }
    
    try {
      const timezone = await axios.get(`${API_BASE_URL}/timezone`, {
        params: {
          lat: place.lat,
          lng: place.lng,
          timestamp: moment().unix()
        }
      });
      
      if (timezone.data.status === "OK") {
        setHomeLocation((prev) => ({
          ...prev,
          city: place.name,
          lat: place.lat,
          lng: place.lng,
          timezone: timezone.data.timeZoneId,
        }));
        
        // Clear any previous errors
        setErrors(prev => ({
          ...prev,
          home: null
        }));
      } else {
        throw new Error(`Timezone API error: ${timezone.data.status}`);
      }
    } catch (error) {
      console.error("Error updating home location:", error);
      setErrors((prev) => ({
        ...prev,
        home: "Failed to update home location. Please try again.",
      }));
    }
  };

  const checkDSTChanges = (startDate, endDate, homeTimezone, destTimezone) => {
    const changes = [];
    const start = moment(startDate);
    const end = moment(endDate);
    
    // Check each day in the date range
    for (let m = start.clone(); m.isBefore(end); m.add(1, "days")) {
      const nextDay = m.clone().add(1, "days");
      
      // Check DST status for both timezones on current and next day
      const homeDSTToday = moment.tz(m, homeTimezone).isDST();
      const homeDSTTomorrow = moment.tz(nextDay, homeTimezone).isDST();
      const destDSTToday = moment.tz(m, destTimezone).isDST();
      const destDSTTomorrow = moment.tz(nextDay, destTimezone).isDST();

      // If either timezone has a DST change
      if (homeDSTToday !== homeDSTTomorrow || destDSTToday !== destDSTTomorrow) {
        const beforeHours = getWorkHoursInDestTimezone(m, homeTimezone, destTimezone);
        const afterHours = getWorkHoursInDestTimezone(nextDay, homeTimezone, destTimezone);

        // Calculate the actual time difference including DST changes
        const beforeOffset = moment.tz(m, destTimezone).utcOffset() - moment.tz(m, homeTimezone).utcOffset();
        const afterOffset = moment.tz(nextDay, destTimezone).utcOffset() - moment.tz(nextDay, homeTimezone).utcOffset();

        // Only add change if the offset actually changes
        if (beforeOffset !== afterOffset) {
        changes.push({
            date: nextDay.format("YYYY-MM-DD"),
            beforeHours,
            afterHours,
            dstChange: {
              home: homeDSTToday !== homeDSTTomorrow,
              destination: destDSTToday !== destDSTTomorrow,
              offsetChange: afterOffset - beforeOffset
            }
          });
        }
      }
    }
    
    return changes;
  };

  const handleGenerateClick = useCallback(async () => {
    setIsGenerating(true);
    console.log('Current destinations before generate:', destinations);
    
    // First validate all fields are filled
    const newErrors = { 
      home: null,
      workHours: null,
      workDays: null,
      destinations: destinations.map(() => null),
    };
    let hasErrors = false;

    // Validate home location
    if (!homeLocation?.city) {
      newErrors.home = "Please select your home city from the suggestions";
      hasErrors = true;
    }

    // Validate work hours
    if (!homeLocation?.workHours?.start || !homeLocation?.workHours?.end) {
      newErrors.workHours = "Please set your work hours";
      hasErrors = true;
    }
    
    // Validate work days
    if (!homeLocation?.workDays?.length) {
      newErrors.workDays = "Please select at least one work day";
      hasErrors = true;
    }

    // Validate destinations
    destinations.forEach((dest, index) => {
      if (!dest.city) {
        if (!Array.isArray(newErrors.destinations)) {
          newErrors.destinations = [];
        }
        newErrors.destinations[index] = "Please select a destination city from the suggestions";
        hasErrors = true;
      } else if (!dest.startDate) {
        newErrors.destinations[index] = "Please select an arrival date";
        hasErrors = true;
      } else if (!dest.endDate) {
        newErrors.destinations[index] = "Please select a departure date";
        hasErrors = true;
      }
    });

    setErrors(newErrors);
    if (hasErrors) {
      setIsGenerating(false);
      return;
    }
    
    // Save current state when generating
    saveToLocalStorage({
      homeLocation,
      destinations,
    });
    
    try {
      const allSchedules = [];
      
      // Create a deep copy of destinations to work with
      const workingDestinations = JSON.parse(JSON.stringify(destinations));
      
      // Get home timezone once
      const homeTimezone = await axios.get(`${API_BASE_URL}/timezone`, {
        params: {
          lat: homeLocation.lat,
          lng: homeLocation.lng,
          timestamp: moment().unix()
        }
      });

      for (const destination of workingDestinations) {
        try {
          const destTimezone = await axios.get(`${API_BASE_URL}/timezone`, {
            params: {
              lat: destination.lat,
              lng: destination.lng,
              timestamp: moment(destination.startDate).unix()
            }
          });

          // Check if destination timezone request was successful
          if (destTimezone.data.status !== "OK") {
            throw new Error(
              `Destination timezone API error: ${destTimezone.data.status}`
            );
          }

          console.log("Destination Timezone Response:", destTimezone.data);

          // Get DST changes for this destination
          const dstChanges = checkDSTChanges(
            destination.startDate,
            destination.endDate,
            homeTimezone.data.timeZoneId,
            destTimezone.data.timeZoneId
          );

          // Sort DST changes by date to ensure correct order
          dstChanges.sort((a, b) => moment(a.date).diff(moment(b.date)));

          // Create schedule periods for this destination
          const schedulePeriods = [];
          let currentDate = moment(destination.startDate);

          // Special case for same-day trips with no DST changes
          if (destination.startDate === destination.endDate && dstChanges.length === 0) {
            const singleDayHours = getWorkHoursInDestTimezone(
              currentDate,
              homeTimezone.data.timeZoneId,
              destTimezone.data.timeZoneId
            );
            
            schedulePeriods.push({
              startDate: destination.startDate,
              endDate: destination.endDate,
              workHours: {
                start: moment(singleDayHours.start, "HH:mm").format("h:mm A"),
                end: moment(singleDayHours.end, "HH:mm").format("h:mm A")
              },
              workDays: singleDayHours.workDays
            });
          } else {
            // Create periods for each DST change
            dstChanges.forEach((change, index) => {
              const periodStart = currentDate.format("YYYY-MM-DD");
              // Ensure periodEnd is not before periodStart (handles same-day case)
              const changeDate = moment(change.date);
              const periodEnd = changeDate.isSame(currentDate, 'day') 
                ? periodStart 
                : changeDate.subtract(1, "days").format("YYYY-MM-DD");
              
              // Get work hours for this period
              const periodHours = getWorkHoursInDestTimezone(
                currentDate,
                homeTimezone.data.timeZoneId,
                destTimezone.data.timeZoneId
              );
              
              schedulePeriods.push({
                startDate: periodStart,
                endDate: periodEnd,
                workHours: {
                  start: moment(periodHours.start, "HH:mm").format("h:mm A"),
                  end: moment(periodHours.end, "HH:mm").format("h:mm A")
                },
                workDays: periodHours.workDays
              });
              
              currentDate = moment(change.date);
            });

            // Add final period after last DST change
            if (currentDate.isSameOrBefore(moment(destination.endDate))) {
              const lastHours = getWorkHoursInDestTimezone(
                currentDate,
                homeTimezone.data.timeZoneId,
                destTimezone.data.timeZoneId
              );
              
              schedulePeriods.push({
                startDate: currentDate.format("YYYY-MM-DD"),
                endDate: destination.endDate,
                workHours: {
                  start: moment(lastHours.start, "HH:mm").format("h:mm A"),
                  end: moment(lastHours.end, "HH:mm").format("h:mm A")
                },
                workDays: lastHours.workDays
              });
            }
          }

          allSchedules.push({
            city: destination.city,
            lat: destination.lat,
            lng: destination.lng,
            schedulePeriods
          });
        } catch (error) {
          console.error("Error processing destination:", error);
          throw error;
        }
      }

      setConversionResult({
        destinations: allSchedules
      });
      
      // Ensure we preserve the original destinations
      setDestinations(workingDestinations);

      // Use the new scroll function
      setTimeout(scrollToResults, 100);
      
      setIsGenerating(false);
      
    } catch (error) {
      console.error("Conversion error:", error);
      setErrors(prev => ({
        ...prev,
        general: "Failed to generate schedule. Please try again."
      }));
      setIsGenerating(false);
    }
  }, [homeLocation, destinations, errors, saveToLocalStorage]);

  // First, let's create a helper function to format the work days
  const formatWorkDays = (workDays) => {
    if (!workDays || workDays.length === 0) return "No days selected";

    // If all days are selected
    if (workDays.length === 7) return "Every day";

    // If weekdays are selected
    const hasAllWeekdays = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
    ].every((day) => workDays.includes(day));

    if (hasAllWeekdays && workDays.length === 5) return "Monday to Friday";

    // If weekend days are selected
    const hasAllWeekend = ["saturday", "sunday"].every((day) =>
      workDays.includes(day)
    );

    if (hasAllWeekend && workDays.length === 2) return "Saturday and Sunday";

    // Custom formatting for other combinations
    const dayMap = {
      monday: "Mon",
      tuesday: "Tue",
      wednesday: "Wed",
      thursday: "Thu",
      friday: "Fri",
      saturday: "Sat",
      sunday: "Sun",
    };

    // Sort days in week order
    const weekOrder = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ];
    const sortedDays = [...workDays].sort(
      (a, b) => weekOrder.indexOf(a) - weekOrder.indexOf(b)
    );

    return sortedDays.map((day) => dayMap[day]).join(", ");
  };

  const generateShareableText = () => {
    let text = `Work Schedule\n\n`;
    text += `Home Work Schedule (${homeLocation.city})\n`;
    text += `Work Hours: ${moment(homeLocation.workHours.start, "HH:mm").format("h:mm A")} - ${moment(homeLocation.workHours.end, "HH:mm").format("h:mm A")}\n`;
    text += `Work Days: ${formatWorkDays(homeLocation.workDays)}\n\n`;

    text += `Destinations:\n`;
    conversionResult.destinations.forEach((destination, index) => {
      text += `\nDestination ${index + 1}: ${destination.city}\n`;
      destination.schedulePeriods.forEach((period) => {
        text += `${moment(period.startDate).format("MMM D")} - ${moment(
          period.endDate
        ).format("MMM D, YYYY")}\n`;
        text += `Work hours: ${period.workHours.start} - ${period.workHours.end}\n`;
        text += `Days: ${formatWorkDays(period.workDays)}\n`;
        text += `\n`;
      });
    });

    return text;
  };

  const handleShare = async () => {
    try {
      const scheduleText = generateShareableText();

      if (navigator.share) {
        // Use Web Share API if available
        await navigator.share({
          title: "Work Schedule",
          text: scheduleText,
        });
      } else {
        // Fallback to clipboard copy
        await navigator.clipboard.writeText(scheduleText);
        setShareStatus({
          copied: true,
          error: null,
        });
        setTimeout(() => {
          setShareStatus({
            copied: false,
            error: null,
          });
        }, 2000);
      }
    } catch (error) {
      // Only show error for clipboard failures
      if (!navigator.share) {
        setShareStatus({
          copied: false,
          error: "Failed to copy schedule",
        });
      }
    }
  };

  // In the TimeConverter component
  const handleDestinationsChange = (newDestinations) => {
    setDestinations(newDestinations);
    
    // Clear errors for destinations that have valid cities
    setErrors(prev => {
      const newDestinationErrors = newDestinations.map((dest, index) => {
        // If this destination has a city, clear its error
        if (dest.city) {
          return null;
        }
        // Otherwise keep the existing error (if any)
        return prev.destinations && prev.destinations[index] 
          ? prev.destinations[index] 
          : null;
      });
      
      return {
        ...prev,
        destinations: newDestinationErrors
      };
    });
  };

  // Memoize the destinations display
  const destinationsDisplay = useMemo(() => (
    <DestinationSelector
      destinations={destinations}
      onChange={handleDestinationsChange}
      errors={errors.destinations}
    />
  ), [destinations, errors.destinations]);

  const handleHomeLocationChange = (field, value) => {
    if (field === "city" && !value) {
      // If clearing the city, also clear coordinates
      setHomeLocation({
        ...homeLocation,
        city: "",
        lat: null,
        lng: null,
      });
      return;
    }
    
    // Clear work days error when days are selected
    if (field === "workDays" && value.length > 0) {
      setErrors(prev => ({
        ...prev,
        workDays: null
      }));
    }
    
    setHomeLocation({
      ...homeLocation,
      [field]: value,
    });
  };

  return (
    <div className="container px-4 sm:px-6 py-8 sm:py-12 mx-auto max-w-5xl">
      {/* Header */}
      <div className="text-center mb-12 sm:mb-16 relative px-4">
        <h1 className="h1 sm:text-[2rem] font-semibold mb-4">
          Explore the World, Keep Your Work Schedule
        </h1>
        <p className="body-text text-muted-foreground max-w-[600px] mx-auto opacity-90">
          Plan your remote work travels with confidence. Calculate work hours for any destination, discover ideal cities for your time zone, and avoid booking trips that lead to 3 AM meetings.
        </p>
      </div>

      <Card className="backdrop-blur-sm bg-white/80 shadow-xl">
        <CardContent className="p-4 sm:p-8 space-y-8 sm:space-y-12">
          {/* Map Section */}
          <TimeZoneMap
            homeLocation={homeLocation}
            destinations={destinations}
            workHours={homeLocation.workHours}
          />

          {/* Home Location */}
          <div className="p-6 sm:p-8 bg-gradient-to-br from-brand-primary/5 to-brand-secondary/5 rounded-2xl border border-brand-primary/10">
            <div className="flex items-center gap-3 mb-4">
              <IconHome size={20} className="text-brand-primary" />
              <h2 className="text-xl font-semibold">Your Home Work Schedule</h2>
            </div>
            <p className="text-sm text-muted-foreground/80 mb-8">
              Set your regular work schedule - all destination times will be calculated based on these hours
            </p>
            
            <div className="space-y-6">
              <div>
                <Label className="text-sm font-medium mb-2 block">City</Label>
                <CityAutocomplete
                  placeholder="e.g., San Francisco, New York, London"
                  value={homeLocation.city}
                  onChange={(value) =>
                    handleHomeLocationChange("city", value)
                  }
                  onPlaceSelected={handleHomeLocationSelected}
                  error={errors.home}
                />
                {errors.home && (
                  <p className="text-destructive text-sm mt-1">{errors.home}</p>
                )}
              </div>

                <div>
                <Label className="text-sm font-medium mb-2 block">Regular Work Hours</Label>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                  <TimeSelect
                    label="Start Time"
                    value={homeLocation.workHours.start}
                    onChange={(value) =>
                      handleHomeLocationChange("workHours", {
                        ...homeLocation.workHours,
                        start: value,
                      })
                    }
                    error={errors.workHours}
                  />
                  <TimeSelect
                    label="End Time"
                    value={homeLocation.workHours.end}
                    onChange={(value) =>
                      handleHomeLocationChange("workHours", {
                        ...homeLocation.workHours,
                        end: value,
                      })
                    }
                    error={errors.workHours}
                  />
                </div>
                {errors.workHours && (
                  <p className="text-sm text-destructive mt-2">
                    {errors.workHours}
                  </p>
                )}
              </div>

              <div>
                <Label className="text-sm font-medium mb-2 block">Work Days</Label>
                <WorkdaySelector
                  selectedDays={homeLocation.workDays}
                  onChange={(days) => handleHomeLocationChange("workDays", days)}
                />
                {errors.workDays && (
                  <p className="text-destructive text-sm mt-1">{errors.workDays}</p>
                )}
              </div>
            </div>
          </div>

          {/* Travel Plans */}
          <div className="p-6 sm:p-8 bg-gradient-to-br from-brand-primary/5 to-brand-secondary/5 rounded-2xl border border-brand-primary/10">
            <div className="flex items-center gap-3 mb-4">
              <IconPlane size={20} className="text-brand-primary" />
              <h2 className="text-xl font-semibold">Your Travel Schedule</h2>
            </div>
            
            {destinationsDisplay}
          </div>

          {/* Action Buttons */}
          <div className="flex flex-col sm:flex-row gap-3 pt-4 sm:justify-end">
            <Button
              onClick={handleGenerateClick} 
              className="gap-2 bg-brand-primary hover:bg-brand-primary/90 text-white px-6 sm:px-8 py-4 sm:py-6 text-base sm:text-lg shadow-lg hover:shadow-xl transition-all w-full sm:w-auto mx-auto"
              disabled={isGenerating}
            >
              <>
                {isGenerating ? "Generating..." : "Generate My Work Hours"}
                <IconArrowRight size={20} />
              </>
            </Button>
          </div>
        </CardContent>
      </Card>

      {/* Results Section */}
      {conversionResult && (
        <Card 
          className="mt-12 border-2 border-brand-primary/10 shadow-lg transition-all duration-300 min-h-[300px]"
          ref={resultRef}
        >
          <CardHeader>
            <div className="flex items-center justify-between">
              <div className="space-y-2">
                <CardTitle className="text-xl font-semibold">Your Adjusted Work Hours</CardTitle>
                <p className="text-sm text-muted-foreground/80">
                  Hours adjusted to match your home schedule from {homeLocation.city} in each destination
                </p>
              </div>
              <Button
                variant="outline"
                size="sm"
                onClick={handleShare}
                className="gap-2 ml-4 whitespace-nowrap px-3 sm:px-4"
                disabled={shareStatus.copied}
              >
                {shareStatus.copied ? (
                  <>
                    <IconCheck size={16} className="text-green-600" />
                    Copied!
                  </>
                ) : navigator.share ? (
                  <>
                    <IconShare size={16} />
                    <span className="hidden sm:inline">Share With Team</span>
                    <span className="sm:hidden">Share</span>
                  </>
                ) : (
                  <>
                    <IconCopy size={16} />
                    <span className="hidden sm:inline">Copy to Share</span>
                    <span className="sm:hidden">Copy</span>
                  </>
                )}
              </Button>
            </div>
            {shareStatus.error && (
              <p className="text-sm text-destructive mt-2">
                {shareStatus.error}
              </p>
            )}
          </CardHeader>
          <CardContent className="space-y-8">
            {conversionResult.destinations.map((destination, destIndex) => (
              <div 
                key={destIndex}
              >
                <div className="flex items-center gap-2 mb-4">
                  <IconPlane size={20} className="text-brand-primary" />
                  <h3 className="h3">
                    {destination.city} Schedule
                  </h3>
                </div>
                <div className="space-y-4">
                  {destination.schedulePeriods.map((period, periodIndex) => (
                    <Card key={`${destIndex}-${periodIndex}`}>
                  <CardContent className="p-4 space-y-3">
                        <p className="text-lg font-medium">
                          {moment(period.startDate).format("MMM DD")} -{" "}
                          {moment(period.endDate).format("MMM DD, YYYY")}
                    </p>
                    
                        <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-2">
                            <IconClock size={16} className="text-brand-primary" />
                            <p className="text-base">
                              Your {destination.city} work hours:{" "}
                              <span className="font-medium">
                                {period.workHours.start} - {period.workHours.end}
                              </span>
                            </p>
                          </div>
                          <p className="text-sm text-muted-foreground/80 pl-6">
                            (equivalent to your {moment(homeLocation.workHours.start, "HH:mm").format("h:mm A")} - {moment(homeLocation.workHours.end, "HH:mm").format("h:mm A")} in {homeLocation.city})
                      </p>
                    </div>
                    
                    <div className="flex items-center gap-2">
                          <IconCalendar
                            size={16}
                            className="text-brand-primary"
                          />
                          <p>Days: {formatWorkDays(period.workDays)}</p>
                    </div>
                    
                        {periodIndex <
                          destination.schedulePeriods.length - 1 && (
                          <div className="mt-3 p-3 bg-brand-primary/5 rounded-lg border border-brand-primary/10">
                            <div className="flex items-start gap-2">
                              <IconAlertCircle size={16} className="text-brand-primary shrink-0 mt-0.5" />
                              <div className="space-y-1">
                                <p className="text-sm font-medium text-brand-primary">
                                  Daylight Saving Time Change on {moment(destination.schedulePeriods[periodIndex + 1].startDate).format("MMM D, YYYY")}
                                </p>
                                <p className="text-sm text-muted-foreground/80">
                                  Your work hours will automatically adjust to maintain alignment with {homeLocation.city} time.
                                  See the next period for updated hours.
                                </p>
                              </div>
                            </div>
                          </div>
                    )}
                  </CardContent>
                </Card>
                  ))}
                </div>
              </div>
            ))}
            
            {/* Scroll to top button */}
            <div className="fixed bottom-8 right-8 z-50">
              <Button
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                className="rounded-full w-12 h-12 flex items-center justify-center bg-brand-primary text-white shadow-lg hover:shadow-xl"
                aria-label="Scroll to top"
              >
                <IconArrowUp size={20} />
              </Button>
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default WorkTimeConverter; 
